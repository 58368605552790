.work {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.work-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0px;
}